export const LEVEL_OF_STUDY = [
  "Certificat d’études primaires (CEP)",
  "BEPC, brevet élémentaire, brevet des collèges, DNB",
  "CAP, BEP, brevet de compagnon, ou diplôme de niveau équivalent",
  "Baccalauréat général ou technologique, brevet supérieur, capacité en droit, DAEU, ESEU",
  "Baccalauréat professionnel, brevet professionnel, de technicien, de maîtrise ou d’enseignement, diplôme équivalent",
  "Diplôme technique de 1er cycle : BTS, DUT, Deug, Deust, diplôme de la santé ou du social de niveau bac+2, diplôme équivalent",
  "Diplôme universitaire ou général de 1er cycle : licence, licence professionnelle, maîtrise, diplôme équivalent de niveau bac+3 ou bac+4",
  "Diplôme universitaire de 3ème cycle : Master, DEA, DESS, diplôme de grande école, diplôme de niveau bac+5, doctorat de santé",
  "Doctorat de recherche (hors santé)",
  "Aucun diplôme"
];

export const LABOUR_MARKET_SITUATION = [
  "En activité : salarié en CDI",
  "En activité : salarié en CDD / intérimaire",
  "En activité : chef d'entreprise / gérant",
  "En activité : micro-entrepreneur / freelance",
  "Sans emploi : allocataire du chômage",
  "Sans emploi : allocataire du RSA",
  "Sans emploi : sans revenu",
  "En Contrat d'Engagement Jeune",
  "Étudiant / En formation",
  "Autre"
];

export const SORTIE_BENEFICIAIRE = [
  "Création",
  "Formation",
  "Emploi",
  "Pas de réponse",
  "Autre"
];

export const QPV = [
  "Air Bel",
  "Balustres Cerisaie",
  "Benza",
  "Bon Secours Les Rosiers Marine Bleue Grand Saint Barthélémy Saint Jérôme",
  "Caillols La Moularde",
  "Centre Ville Canet Arnavaux Jean Jaurès",
  "Château Saint Loup",
  "Consolat Ruisseau Mirabeau",
  "Frais Vallon Le Clos La Rose",
  "Kalliste La Granière La Solidarité",
  "La Cabucelle",
  "La Calade Campagne Lévèque",
  "La Capelette",
  "La Castellane La Bricarde Plan D'Aou Saint Antoine",
  "La Cayolle",
  "La Cravache Le Trioulet",
  "La Marie",
  "La Rouguière",
  "La Sauvagère",
  "La Savine",
  "La Simiane La Paternelle",
  "La Soude Bengale",
  "La Visitation - Bassens",
  "La Viste",
  "Le Castellas Les Micocouliers Saint Joseph",
  "Le Petit Séminaire",
  "Les Aygalades",
  "Les Escourtines",
  "Les Olives",
  "Les Tilleuls La Maurelette",
  "Malpassé Corot",
  "Saint André",
  "Saint Henri",
  "Saint Thys",
  "Valbarelle Néréïdes Bosquet"
];

export const RECEPTION_MODALITY = ["Physique", "Téléphone", "En ligne"];

export const PRESCRIBER = [
  "Partenaire création (dont Capital jeunes créateurs)",
  "SPE / Partenaire institutionnel",
  "Web / Réseaux sociaux",
  "Relation / Bouche à oreille",
  "Entreprise / Réseau d'entreprises",
  "Partenaire associatif (dont guichet des associations)",
  "Action directe de mon Carrefour de l'entrepreneuriat",
  "Autre"
];

export const TYPE_OBSTACLES_ENCOUNTRED = [
  "Exclusion numérique",
  "Etat de santé",
  "Mobilité",
  "Difficultés financières",
  "Contraintes familiales",
  "Capacités d’insertion",
  "Difficultés administratives/juridiques",
  "Difficultés de logement",
  "Autre"
];

export const PROJECT_MATURITY = [
  "Envie",
  "Idée",
  "Actions concrètes amorcées / étude marché",
  "Structuration du projet finalisée / business plan",
  "Déjà immatriculé"
];

export const PROJECT_ACTIVITY_SECTOR = [
  "Agriculture",
  "Agroalimentaire, Restauration, Traiteur",
  "Banque, Finance, Assurance",
  "Bâtiment, BTP, Urbanisme",
  "Bien-être, Beauté",
  "Artisanat",
  "Commerce de proximité",
  "Culture, Arts, Spectacle",
  "Distribution, Négoce",
  "Emploi, Éducation, Formation",
  "Développement durable, énergie, environnement, économie circulaire",
  "Établissement recevant du public",
  "Industrie, Produits manufacturés",
  "Informatique, Télécom, Internet",
  "Lien social, Inclusion, Action sociale, Humanitaire",
  "Logistique, Transports, Stockage",
  "Mobilité et villes",
  "Mode et retail",
  "Petite enfance",
  "Service à la personne",
  "Santé et vieillesse",
  "Services aux entreprises, Études, Conseil, Audit",
  "Social, Solidarité",
  "Tourisme, Loisirs, Voyage",
  "Secteur public",
  "Web, numérique, nouvelles technologies"
];
